
import { Component, Vue } from "vue-property-decorator";
import HousfyPage from "@/components/protected/HousfyPage.vue";
import MoreInformationFlapForm from "@/components/forms/MoreInformationFlapForm.vue";
import ToolsList from "@/components/ToolsList.vue";
import OwnerLeadsShimmer from "@/components/OwnerLeadsShimmer.vue";
import {
  HousfyBannerWithCheckList,
  HousfyAlertMessage,
  HousfyPropertyCard,
} from "housfy-ui-lib";
import api from "@/api";
import ErrorService from "@/services/ErrorService";
import { IHousfyError } from "@/models/HousfyError";
import { IBackOptions } from "@/models/BackOptions";
import { TranslateResult } from "vue-i18n";
import { LeadOrigin } from "@/constants/OriginIds";
import MYHExternalRoutes from "@/services/MYHExternalRoutes";
import { resolveRoutePath } from "@/router";
import { HousfyButton } from "housfy-ui-lib";
import HousfySvg from "@/components/ui/HousfySvg.vue";
import { TabbarIds } from "@/enums/HousfyPage";
import OwnerProperties from "@/components/OwnerProperties.vue";
import { IOwnerProperties, IOwnerProperty } from "@/models/OwnerProperties";
import AuthService from "@/services/AuthService";

@Component({
  name: "Owner",
  components: {
    HousfyPage,
    HousfyBannerWithCheckList,
    HousfyAlertMessage,
    HousfyPropertyCard,
    OwnerLeadsShimmer,
    MoreInformationFlapForm,
    ToolsList,
    OwnerProperties,
    HousfyButton,
    HousfySvg,
  },
})
export default class Owner extends Vue {
  ownerProperties: IOwnerProperties = { items: [] };
  loading = false;
  formFlap = false;
  formId = "0";
  successAlert = false;
  originId = LeadOrigin.OWNER;
  activeTabId = TabbarIds.ACTIVITY;
  showPageSubtitle = false;
  propertiesCount = 0;

  get pageSubtitle(): TranslateResult {
    this.propertiesCount = this.ownerProperties.items.length;

    if (!this.propertiesCount) {
      this.showPageSubtitle = true;
      return this.$t("common.youHaveNoActiveRentals");
    }
    return this.$t("common.youHaveAddedXProperties", {
      ownerLeadsCount: this.propertiesCount,
      properties: this.$tc("common.properties", this.propertiesCount),
    });
  }
  get backOptions(): IBackOptions {
    return {
      enabled: true,
      text: this.$t("common.back"),
      minimal: this.$mqm,
      preventDefault: false,
      fallbackHref: resolveRoutePath(MYHExternalRoutes.myhHome()),
    };
  }
  get bannerList(): TranslateResult[] {
    return [
      this.$t("common.secureCollectionEachMonth"),
      this.$t("common.tenantAndSolvencyAnalysis"),
      this.$t("common.incidentManagement"),
    ];
  }
  get alertMessage(): TranslateResult {
    const thanksText =
      "<strong>" + this.$t("common.thankYouForContactingUs") + "</strong> ";
    const callText = this.$t("common.weWillCallYouBack");
    const desktopMessage = thanksText + "<br/>" + callText;
    const mobileMessage = thanksText + callText;

    return this.$mqm ? mobileMessage : desktopMessage;
  }
  getCustomPropertiesList(propertiesList: IOwnerProperty[]): IOwnerProperty[] {
    const propertyIdsBanned = [309410, 309416];
    return propertiesList.filter(
      (property) => !propertyIdsBanned.includes(property.propertyId)
    );
  }
  async mounted() {
    try {
      this.loading = true;
      this.ownerProperties = await api.owner().retrieveOwnerProperties();
      const user = AuthService.currentUser;
      const userIdToReview = 109224;
      if (user.id === userIdToReview)
        this.ownerProperties.items = this.getCustomPropertiesList(
          this.ownerProperties.items
        ); //do not replicate this logic with other users except if product requires it. It is a special case
    } catch (error) {
      ErrorService.handleGenericError(error as IHousfyError);
    } finally {
      this.loading = false;
    }
    window.addEventListener("cardsMounted", () => {
      this.showPageSubtitle = true;
    });
  }
  openForm(formId: string) {
    this.formFlap = true;
    this.formId = formId;
  }
  handleFormSuccess() {
    this.formFlap = false;
    this.successAlert = true;
  }
}
